.info {
    font-size: 14px;
    color: #007bff;
    margin-top: 5px;
}

.error {
    font-size: 14px;
    color: #dc3545;
    margin-top: 5px;
}

.success {
    font-size: 14px;
    color: #28a745;
    margin-top: 5px;
}


.otp-boxes {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.otp-boxes input {
    width: 50px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border-color 0.3s;
    border-color: #3B3AF8;
}

.otp-boxes input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


.otp-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
  }

  .otp-section button{
    border: none;
    background: none;
    color: #007bff;
  }

  .otp-section h2{
    font-weight: 500;
    font-size: 24px;
    color: #3B3AF8;
  }

  .verify-page-right{
    background: #3B3AF8;
    text-align: center;
  }