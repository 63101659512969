@media screen and (min-width: 768px) and (max-width: 1250px) {
  .home .home-sec {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home .home-sec img {
    width: 100vw !important;
  }

  .team .team-container {
    margin: 10px !important;
  }

  .team .team-member {
    max-width: 100%;
    overflow: hidden;
  }

  .email-link {
    display: inline-block;
    /* Required for ellipsis to work */
    max-width: 100%;
    /* Limit the width of the link */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Add ellipsis to overflowing text */
    color: #4B4949;
  }

  .profile .form-head{
    display: flex;
    flex-direction: column;
    
  }

  .profile .form-head .profile-rows{
    width: 100%;
  }

}

@media screen and (max-width: 767px) {

  .home h1 {
    font-size: 40px;
  }

  .home .center-box .first-container {
    flex-direction: column;
  }

  .mission-container {
    flex-direction: column;
    /* Stack the sides vertically */
  }

  .mission-left,
  .mission-right {
    flex: none;
    /* Prevents them from stretching unnecessarily */
    width: 100%;
    /* Ensures full width for both sides */
    height: auto;
    /* Adjusts the height automatically */
    margin: 0;
    /* Removes unwanted margin */
    padding: 10px;
    /* Adds some padding for content spacing */
  }

  .image-container img {
    max-width: 100%;
    /* Ensures the image fits within the container */
    height: auto;
    /* Adjusts height proportionally */
  }

  .mission-right {
    padding: 15px;
    /* Adjusts padding for text readability */
  }

  /* Make table rows stack vertically on small screens */
  .basicdetail table,
  .basicdetail td,
  .basicdetail th {
    display: block;
    width: 100%;
  }

  /* Remove the horizontal lines between rows for mobile */
  .basicdetail tr {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }

  /* Style the table cells for better readability on small screens */
  .basicdetail td {
    padding: 8px;
    font-size: 14px;
  }

  /* Make the first column stand out */
  .basicdetail td:first-child {
    font-weight: 500;
  }

  /* Remove any borders or excessive padding in mobile view */
  .basicdetail td a {
    text-decoration: none;
  }

  /* Adjust the table headings for mobile */
  .basicdetail th {
    display: none;
  }

  /* Adjust margins for better spacing */
  .basicdetail p {
    font-size: 14px;
    padding: 0 10px;
  }

  .resource {
    padding: 0 !important;
    margin: 0 !important;
  }

  .calculator{
    padding: 0 !important;
    margin: 0 !important;
  }

  .contact .row>.col-md-6 {
    margin-bottom: 15px;
  }

  .profile .profile-rows{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
  }


  .card{
    width: 300px; /* Card width */
  height: 360px; /* Fixed height */
  }

  .card .card-detail{
    width: 300px; /* Card width */
  height: 360px; /* Fixed height */
  }

  .trade{
    align-items: center;
    text-align: center;
  }


  .quicklink{
    flex-direction: column;
  }

  
}


@media screen and (max-width: 400px) {
  .home h1 {
    font-size: 25px;
  }

  .home .btn button {
    padding: 8px 20px;
  }

  .about-container {
    padding: 20px !important;
  }

  .team .team-container {
    margin: 10px !important;
  }

  .email-link {
    display: inline-block;
    /* Required for ellipsis to work */
    max-width: 100%;
    /* Limit the width of the link */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Add ellipsis to overflowing text */
    color: #4B4949;
  }

  .card{
    width: 300px; /* Card width */
  height: 360px; /* Fixed height */
  }

  .card .card-detail{
    width: 300px; /* Card width */
  height: 360px; /* Fixed height */
  }

  .cal-right .cal-doughnut{
    width: 250px !important;
    height: 250px !important;
  }

}


@media screen and (max-width: 300px) {
  .team .team-container {
    margin: 5px !important;
  }

  .team .team-member {
    max-width: 100%;
    overflow: hidden;
  }

  .email-link {
    display: inline-block;
    /* Required for ellipsis to work */
    max-width: 100%;
    /* Limit the width of the link */
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Add ellipsis to overflowing text */
    color: #4B4949;
  }

}