/* 

Home page style 
    ==> File component style

*/

/* About page style */

/* Resource page style */

/* Contact page style */

/* Privacy Policy page style */

/* Terms And Condition Page style */

/* Refund Cancellation Page style */

/* Login page style */

/* Dashboard page style */

/* Profile form page style */



/* Home page style */
.home {
  min-height: 90vh;
  background: linear-gradient(to top right, #fedfe4, #FFFFFF, #a6b1ff);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.home h1 {
  font-weight: 600;
  font-size: 45px;
  background: linear-gradient(to right, #3B3AF8 10%, #232292 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.home .btn {
  border: none;
}

.home .btn button {
  padding: 10px 35px;
  border: 1px solid #3B3AF8;
  border-radius: 25px;
  color: #3B3AF8;
  background: transparent;
}

.home .btn button:hover {
  background: linear-gradient(to bottom, #3B3AF8 0%, #232292 100%);
  color: white;
}


.account {
  max-height: 100vh;
  background: #3B3AF8;
}

.links {
  background: #232292;
}

.file-container {
  margin: 20px auto;
  padding: 10px;
  max-width: 100%;
}

.file-links {
  display: flex;
  flex-wrap: wrap;
  /* Wrap links to a new row when needed */
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.file-link {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  transition: color 0.3s, background-color 0.3s;
  white-space: nowrap;
  /* Prevent wrapping */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Add ellipsis (...) */
  max-width: 100%;
  /* Limit width to prevent overflow */
  display: inline-block;
  /* Needed for truncation */
}


.file-link:hover {
  color: #fff;
  text-decoration: underline;
}

.separator {
  color: #FFFFFF;
  margin: 0 5px;
  user-select: none;
  /* Prevent selection of the separator */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .file-container {
    padding: 10px;
  }

  .file-links {
    gap: 8px;
    justify-content: flex-start;
  }

  .file-link {
    font-size: 12px;
    padding: 5px;
    max-width: 90%;
  }

  .separator {
    margin: 0 3px;
  }
}

@media (max-width: 350px) {
  .separator {
    visibility: hidden;
  }
}


/* General Swiper Slide Styling */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Custom Navigation Buttons */
.carousel .custom-nav {
  background-color: #6d78ff;
  /* Background color */
  color: white;
  /* Arrow color */
  border-radius: 50%;
  /* Makes the button circular */
  width: 50px;
  /* Width of the button */
  height: 50px;
  /* Height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

/* Positioning Navigation Arrows */
.carousel .swiper-button-prev {
  left: 20px;
}

.carousel .swiper-button-next {
  right: 20px;
}

/* Navigation Icon */
.carousel .custom-nav::after {
  font-size: 18px;
  font-weight: bold;
}

/* Hover Effect */
.carousel .custom-nav:hover {
  transform: scale(1.1);
  /* Slightly enlarges on hover */
  background-color: #a264ff;
  /* Change background color */
}



/* Card Styling for All Slides */
.card {
  background-color: white;
  /* Original color */
  display: flex;
  justify-content: end;
  align-items: end;
  width: 500px;
  /* Card width */
  height: 330px;
  /* Fixed height */
}

.card button {
  width: fit-content;
  margin-right: 20px;
  border: 1px solid #b4c0f9;
  border-radius: 10px;
  padding: .7rem .5rem;
  background: white
}

.card .card-detail {
  background: linear-gradient(to top, #E4E3FF, #FFFFFF);
  /* Original color */
  color: #A4ADBB;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #b4c0f9;
  text-align: left;
  width: 500px;
  /* Card width */
  height: 300px;
  /* Fixed height */
  transition: all 0.3s ease;
  transform: scale(0.9);
  /* Default smaller size */
}

.card .card-detail h3 {
  color: #000000;
}

.learn-more .arrow {
  font-size: 1rem;
  border: 1px solid #b4c0f9;
  border-radius: 50%;
  background: white;
  color: #004BD2;
  padding: .2rem 0;
}

.learn-more .arrow-box {
  background: linear-gradient(to bottom, #004BD2, #99BBF8);
}


/* Highlight Active Slide */
.swiper-slide-active .card {
  opacity: 1;
  /* Full opacity */
  background: white;
  /* Original color */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  /* Enhanced shadow for focus */
}


.swiper-slide-active .card .card-detail {
  opacity: 1;
  /* Full opacity */
  background: linear-gradient(35deg, #3B3AF8, #C6D0FF);
  /* Original color */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  color: white;
}

.swiper-slide-active .card .card-detail h3 {
  color: white;
}

.trade {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.trade ul{
  margin-top: -20px;
  text-align: left;
}

.trade button {
  background: linear-gradient(to right, #5973EE, #EE8188);
  border: none;
  color: white;
  border-radius: 25px;
  padding: .5rem 2rem;
  width: fit-content;
}

.trade p {
  color: #3B3AF8;
}

.trade p .arrow {
  border-radius: 50%;
  background: #3B3AF8;
  color: white;
  padding: .2rem 0;
}


.demat {
  background: linear-gradient(to right, #b6b6f8, #f9b6c0);
}

.demat .demat-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  text-align: left;
}

.demat .demat-right button {
  width: fit-content;
  padding: .5rem 2rem;
  color: white;
  background: #3B3AF8;
  border: none;
  font-weight: bold;
}

.home-invest-sec h2{
  font-weight: bold;
}

.home-invest-sec
h6 {
  font-weight: 600;
  color: #0123FF;
}

.home-invest-sec p {
  color: #5D6775;
}

.invest-box {
  background: #e5e5fd;
  display: flex;
  gap: 20px;
}

.invest-box .hr {
  width: 25px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #3B3AF8;
}




/* About page style */

.about {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-head {
  width: 100%;
  background: #2B4EF4;
  margin: 0;
}

.about-head h1 {
  color: #FFFFFF;
  text-align: center;

}

.about-container {
  text-align: center;
  /* Overlap the about-head section */
}


.about-container h2 {
  font-weight: 600;
}

.transparency-left .box {
  width: 60px;
  height: 60px;
  transition: background-color 0.5s ease;
}

.transparency-left h2 {
  font-size: 1.5rem;
}

.transparency-left p {
  font-size: 1rem;
  color: #333;
}

.transparency-left .rectangle {
  width: 8px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #787878;
}


/* Carousel style */

.mission-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* Makes both sides equal height */
  padding: 20px;
  border-radius: 20px;
  margin: 30px 0;
  width: 100%;
}

.mission-left,
.mission-right {
  flex: 1;
  /* Both sides take equal width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centers content vertically within each side */
}

.mission-left {
  width: 50%;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* Ensures the container stretches to full height */
  display: flex;
  align-items: center;
  justify-content: center;
}


.image-container img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.mission-right {
  background-color: #f4f4f4;
  /* Background color for the right side */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #333;
  background: linear-gradient(to bottom, #FFFFFF, #b0b0e9);
  /* Adjust colors as needed */
  text-align: center;
  width: 50%;
}

.mission-right h2 {
  background: linear-gradient(to right, #1110AE 10%, #FE667C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: 600;
}

.team h1 {
  background: linear-gradient(to right, #1110AE 10%, #FE667C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.team .team-member {
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(0, 0, 0, 0.5);
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
}

.team .team-member h5 {
  font-weight: 600;
}

.team .team-member:hover {
  box-shadow: inset 0 4px 8px rgb(0, 0, 0, 0.5);
}

.team .team-member p {
  color: #4B4949;
}



.icon-link {
  text-decoration: none;
}

.icon-circle {
  width: 30px;
  /* Adjust circle size */
  height: 30px;
  /* Adjust circle size */
  background-color: #0a66c2;
  /* LinkedIn blue background */
  border-radius: 50%;
  /* Makes it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* Makes LinkedIn symbol white */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.icon-circle:hover {
  transform: scale(1.1);
  /* Slight zoom effect */
  background-color: #004182;
  /* Darker blue on hover */
}

.icon-circle svg {
  font-size: 14px;
  /* Adjust size of the LinkedIn symbol */
}

.basicdetail table {
  width: 100%;
  border-collapse: collapse;
}

.basicdetail table,
th,
td {
  border: 1px solid #ddd;
}

.basicdetail th,
td {
  padding: 12px;
  text-align: left;
}

.basicdetail tr:nth-child(even) {
  background-color: #F9FBFE;
  color: black;
}

.basicdetail tr:nth-child(odd) {
  color: black;
}



/* Resource page style */

.resource {
  background: #2B4EF4;
  color: white;
  text-align: left;
}

.resource a {
  color: black;
  word-wrap: break-word;
  /* Enable breaking for long links */
  overflow-wrap: anywhere;
}


.calculator{
  background: #2B4EF4;
}




/* Contact page style */

.contact {
  background-image: url('../src/assets/contact-bg.png');
  background-position: center;
  background-size: cover;
  max-width: 100vw;
  overflow: hidden;
}

.contact h1 {
  background: linear-gradient(to right, #1110AE 10%, #FE667C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-weight: 600;
}

.contact .row>.col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Evenly spaces inputs vertically */
}

.contact input,
textarea {
  box-shadow: 0 4px 8px rgb(0, 0, 0, 0.5);
}

textarea {
  resize: none;
  /* Prevents resizing of the message box */
}


.contact .btn {
  background: linear-gradient(to right, #3B3AF8 10%, #232292 100%);
  color: #f4f4f4;
  border-radius: 0;
  width: fit-content;
}

.quicklink-box {
  background-color: #E0E0EC;
  padding: .5rem 2rem;
  text-align: left;
  width: 300px;
}

/* Privacy Policy page style */

#privacypolicy {
  background: #0A20AC;
  color: white;
}

#privacypolicy p {
  color: #BBB5B5;
}

#privacypolicy ul,
li {
  margin-top: 10px;
}

#privacypolicy .li-desc {
  color: #BBB5B5;
}


/* Terms And Condition Page style */

.terms-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.terms-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.terms-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
}

/* Disclaimer Page style */

.disclaimer-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.disclaimer-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.disclaimer-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
}

/* Refund Cancellation Page style */

.refund-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.refund-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.refund-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
}

.refund-subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.refund-note {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  margin-top: 20px;
  text-align: justify;
}


/* Login page style */

.login-page {
  max-height: 100vh;
}

.login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
}

.login-right {
  background: #3B3AF8;
  text-align: center;
  max-height: 100vh;
}

.login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

/* Signup page style */
.signup-page {
  max-height: 100vh;
}

.signup-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
}

.signup-right {
  background: #3B3AF8;
  text-align: center;
  max-height: 100vh;
}


/* Basic form styles */
.signup-page form {
  width: 100%;
  max-width: 500px;
  /* Make the form's max width 500px */
  margin: 0 auto;
  /* Center the form horizontally */
  padding: 10px;
}

.signup-page form div {
  margin-bottom: 15px;
}

.signup-page form label {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.signup-page form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #3B3AF8;
  border-radius: 10px;
  font-size: 14px;
  color: #333;
}

.signup-page form input:focus {
  outline: none;
  border-color: #3B3AF8;
  /* Highlight the border on focus */
}

.signup-page form button {
  width: 100%;
  padding: 10px;
  background-color: #3B3AF8;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.signup-page form button:hover {
  background-color: #2c2fd4;
  /* Darken button on hover */
}


.login-page form {
  width: 500px;
}

.login-page form div {
  margin-bottom: 15px;
}

.login-page form label {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.login-page form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #3B3AF8;
  border-radius: 10px;
  font-size: 14px;
  color: #333;
}

.login-page form input:focus {
  outline: none;
  border-color: #3B3AF8;
  /* Highlight the border on focus */
}

.login-page form button {
  width: 100%;
  padding: 10px;
  background-color: #3B3AF8;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.login-page form button:hover {
  background-color: #2c2fd4;
  /* Darken button on hover */
}


/* Dashboard page style */

.dashboardNav .search input {
  border: none;
  border-radius: 20px;
}

.dashboardNav .search input:focus {
  box-shadow: none;
}

.dashboardNav .search button {
  border: none;
}

.dashboardNav .user-info {
  display: flex;
  align-items: center;
}

.dashboardNav .avatar-circle {
  width: 40px;
  /* Set width of the avatar circle */
  height: 40px;
  /* Set height of the avatar circle */
  background-color: #007bff;
  /* Fallback background color for the circle */
  border-radius: 50%;
  /* Make it circular */
  display: flex;
  justify-content: center;
  /* Center the image horizontally */
  align-items: center;
  /* Center the image vertically */
  overflow: hidden;
  /* Hide anything overflowing the circle */
}

.dashboardNav .avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure image fills the circle */
  border-radius: 50%;
  /* Ensure the image is also circular */
}

.dashboardNav small {
  margin-left: 10px;
  /* Add space between avatar and email */
}


.dashboard {
  max-width: 100vw;
  overflow: hidden;
  margin: 20px;
}


.dashboard .dashboard-com {
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 0 rgb(0, 0, 0, 0.5);
  border-radius: 25px;
}



.dashboard .box {
  box-sizing: border-box;
  border: 1px solid #FE667B;
  border-radius: 20px;
  box-shadow: 1px 3px 1px 0 rgb(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, #eec9cd 10%, #FFFFFF 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 160px;
  width: 180px;
}

.dashboard .box p {
  background: #ED5166;
  width: 100%;
  margin-top: 10px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.dashboard-com {
  position: relative;
  /* Needed for positioning the dropdown correctly */
  text-align: center;
}

.dashboard-com .dropdown-arrow {
  transition: transform 0.2s ease;
  /* Smooth rotation */
  display: inline-block;
  font-size: 20px;
  /* Adjust arrow size */
}

.dashboard-com .dropdown-arrow.rotate {
  transform: rotate(180deg);
  /* Rotates the arrow upwards */
}

.dashboard-com .dropdown-menu-custom {
  position: absolute;
  top: 100%;
  /* Positions the dropdown below the button */
  left: 50%;
  transform: translateX(-50%);
  /* Centers the dropdown */
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
  min-width: 165px;
  text-align: left;
}

.dashboard-com .list-group-item {
  padding: 2px 8px;
  cursor: pointer;
  font-size: 12px;
  border: none;
  /* Removes the border for a cleaner look */
}

.dashboard-com .list-group-item:hover {
  background-color: #f8f9fa;
  /* Adds hover effect */
}

.dashboard-com .btn {
  border: none;
  display: inline-block;
  margin-top: 10px;
  /* Adds spacing between the button and the dropdown */
}


.dashboard .boxes a {
  text-decoration: none;
}

.dashboard .inactive-box {
  position: relative;
  pointer-events: none;
  opacity: 0.6;
}

.dashboard .lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  color: #787878;
  font-size: 14px;
  height: 160px;
  width: 180px;
  border-radius: 20px;
}

.dashboard .lock-icon {
  font-size: 26px;
  margin-bottom: 8px;
}


.image-container {
  position: relative;
  display: inline-block;
}

.image-container .blurred-image {
  filter: blur(4px);
  /* Adjust blur intensity */
  width: 100%;
  height: auto;
}

.image-container .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  font-size: 2rem;
  /* Adjust font size */
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}




/* Profile form page style */

.container.profile {
  margin: 0 auto;
  padding: 30px;
  border-radius: 12px;
}

.container.profile h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container.profile p {
  font-weight: 600;
  font-size: 18px;
}

.container.profile .form-group {
  margin-bottom: 15px;
}

.container.profile label.col-form-label {
  text-align: left;
  font-weight: 500;
  padding-right: 10px;
}

.container.profile input {
  background: #F5F5F5;
}

.container.profile .form-control {
  border-radius: 5px;
}

.container.profile button.btn-primary {
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
  background-color: #3B3AF8;
}

.container.profile button.btn-primary:hover {
  background-color: #3B3AF8;
}


/* Custom Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.profile .form-group .yesno {
  font-size: 20px;
}

.profile .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* Default background for No */
  transition: 0.4s;
  border-radius: 34px;
}

.profile .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  right: 2px;
  /* Initially positioned at the right side */
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.profile input:checked+.slider {
  background-color: #3B3AF8;
  /* Green for Yes */
}

.profile input:checked+.slider:before {
  transform: translateX(-26px);
  /* Moves the round slider to the left for Yes */
  left: 30px;
  /* Adjust left position when it's checked */
  right: auto;
  /* Disable the right positioning when checked */
}


.profile input:not(:checked)+.slider:before {
  transform: translateX(0);
  /* Position the round slider to the right for No */
  left: auto;
  /* Disable the left positioning when unchecked */
  right: 4px;
  /* Position the round slider on the right side by default */
}





footer {
  background: #e7e8fd;
}